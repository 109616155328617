/**
 * Selecteer een element uit de dom en geef een error als deze niet bestaat.
 * @param selectors
 * @param container
 * @param bericht
 * @throws Error als element niet wordt gevonden
 */
export const select = (selectors, container = document, bericht = '') => {
    const el = container.querySelector(selectors);
    if (!el) {
        throw new Error(`Element "${selectors}" niet gevonden. ${bericht}`);
    }
    return el;
};
export const selectAll = (selectors, container = document) => {
    return Array.from(container.querySelectorAll(selectors));
};
export const parents = (element, selector = null) => {
    let parent = element.parentElement;
    if (selector) {
        while (parent && !parent.matches(selector)) {
            parent = parent.parentElement;
        }
    }
    if (!parent) {
        throw new Error('Parent verwacht');
    }
    return parent;
};
