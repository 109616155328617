import axios from 'axios';
function getCsrfHeaders() {
    const idMetaTag = document.querySelector("meta[property='X-CSRF-ID']");
    const valueMetaTag = document.querySelector("meta[property='X-CSRF-VALUE']");
    if (!idMetaTag || !valueMetaTag) {
        throw new Error('Geen CSRF meta tag gevonden');
    }
    return {
        'X-CSRF-ID': idMetaTag.content,
        'X-CSRF-VALUE': valueMetaTag.content,
    };
}
// Extern heeft geen jquery
if (window.$) {
    window.$.ajaxPrefilter((options, originalOptions, jqXHR) => {
        if (!options.crossDomain) {
            const token = getCsrfHeaders();
            jqXHR.setRequestHeader('X-CSRF-ID', token['X-CSRF-ID']);
            jqXHR.setRequestHeader('X-CSRF-VALUE', token['X-CSRF-VALUE']);
        }
    });
}
const backupFetch = window.fetch;
window.fetch = (input, init = {}) => backupFetch(input, Object.assign(Object.assign({}, init), { headers: Object.assign(Object.assign({}, init.headers), getCsrfHeaders()) }));
axios.interceptors.request.use((config) => {
    if (!config.url) {
        return config;
    }
    if (config.url.startsWith(window.location.origin) ||
        config.url.startsWith('/')) {
        return Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, config.headers), getCsrfHeaders()) });
    }
    else {
        return config;
    }
});
