import '../ajax-csrf';
import { docReady } from '../lib/util';
import { select, selectAll } from '../lib/dom';
window.docReady = docReady;
// Versimpelde versie van formSubmit in formulier.js
window.formulier = {
    formSubmit: (event) => event.target.form.submit(),
};
docReady(async () => {
    setTimeout(() => document.body.classList.remove('is-loading'));
    setTimeout(() => import('./extern-defer'));
    const menu = select('#menu');
    const menuKnop = select('.menu-knop');
    document.body.addEventListener('click', (e) => {
        if (!menu.contains(e.target) &&
            !menuKnop.contains(e.target)) {
            menu.classList.remove('show');
        }
    });
    menuKnop.addEventListener('click', (e) => {
        e.preventDefault();
        menu.classList.toggle('show');
        return false;
    });
    selectAll('.expand-dropdown').forEach((knop) => {
        knop.addEventListener('click', (e) => {
            e.preventDefault();
            const parent = knop.parentElement;
            if (!parent) {
                return;
            }
            const parentParent = parent.parentElement;
            if (!parentParent) {
                return;
            }
            const submenu = select('.dropdown', parentParent);
            submenu.classList.toggle('show');
            return false;
        });
    });
});
